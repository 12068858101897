/*******************************
         Site Overrides
*******************************/
.ui.input > input {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;

	/* Gray 5 */
}
