/*******************************
        User Overrides
*******************************/
.ui.dropdown .menu > .divider {
	margin: 0;
}

.ui.top.right.pointing.dropdown > .menu {
	margin: 0;
}

.ui.top.right.pointing.dropdown > .menu:after {
	display: none;
}
