/*******************************
         Site Overrides
*******************************/
.pushable {
	height: calc(100% - 72px);
	overflow-y: auto;
	@media screen and (min-width: 979px) {
		height: calc(100% - 96px);
	}
}

.ui.sidebar {
	top: 72px;
	height: calc(100% - 72px) !important;
	@media screen and (min-width: 979px) {
		top: 96px;
		height: calc(100% - 96px);
	}
}
