/*******************************
         Site Overrides
*******************************/
.ui.form .field > label {
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	font-weight: normal;
	font-family: 'DM Sans';
}

.ui.checkbox label {
	font-family: 'DM Sans' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 14px !important;
	line-height: 18px !important;
	display: flex !important;
	align-items: center !important;
	letter-spacing: 0.03em !important;
	color: #636663 !important;
}

.ui.checkbox {
	-webkit-tap-highlight-color: transparent;
}

.ui.form textarea {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.03em;
}
